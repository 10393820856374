<template>
  <div class="login">
    <div class="login-comp">
      <div class="login-comp-center comp-m">
        <div class="spin-nested-loading">
          <div class="spin-login">
            <div class="game-list">
              <div class="loginForm">
                <div class="title">
                  <i class="el-icon-s-custom"></i>
                  用户登录
                </div>
                <div>
                  <!-- :rules="LoginRules" -->
                  <el-form ref="loginform" label-position="left" style="width: 60%; margin: 0 auto" :model="loginForm"
                    label-width="70px">
                    <el-form-item label="手机号" prop="username">
                      <el-input @keyup.enter.native="ClickLogin" v-model="loginForm.username"></el-input>
                    </el-form-item>
                    <el-form-item label="密码" prop="password">
                      <el-input show-password @keyup.enter.native="ClickLogin" v-model="loginForm.password"></el-input>
                    </el-form-item>
                    <el-row>
                      <el-col :span="12" style="padding-left: 70px;">
                        <span style="color: #13ce66;font-weight: 600;" @click="JumpResigter">前往注册</span>
                      </el-col>
                      <el-col style="text-align: right;font-size: 16px;color: #ccc;" :span="12">
                        <span style="color: rgb(0, 100, 200)" @click="ForgetPasswords">忘记密码</span>
                      </el-col>
                    </el-row>
                  </el-form>
                  <el-row style="width: 200px; margin: 0px auto">
                    <el-col :span="24" style="text-align: center; margin-top: 20px">
                      <el-button v-loading="loading" style="width: 100%" @click="ClickLogin"
                        type="success">登&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;录</el-button>
                    </el-col>
                  </el-row>
                  <el-row style="
                                text-align: center;
                                margin-top: 10px;
                                font-size: 14px;
                                color: #606266;
                              ">
                    <el-col>
                      登录并使用比赛网站需遵守<span @click="JumpUserAgreement" style="color: rgb(0, 100, 200)">《用户注册协议》</span>
                    </el-col>
                  </el-row>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      // 登录表单
      loginForm: {
        username: "",
        password: "",
      },
    };
  },
  methods: {
    // 跳转注册
    JumpResigter() {
      this.$router.push({
        path: "/Resigter"
      })
    },
    // 忘记密码
    ForgetPasswords() {
      this.$router.push('/ForgetPassword');
    },
    JumpUserAgreement() {
      window.open("https://resource.delist.cn/用户注册协议V3.pdf")
    },
    // 登录
    ClickLogin() {
      if (!this.loginForm.username) {
        this.$message({
          message: "请输入手机号",
          type: "warning",
        });
      } else if (!this.loginForm.password) {
        this.$message({
          message: "请输入密码",
          type: "warning",
        });
      } else {
        this.loading = true;
        this.$store
          .dispatch("Login", this.loginForm)
          .then(() => {
            this.loading = false;
            this.loginForm = {};
            this.$router.push({
              path: this.redirect || "/PersonalInfo",
            });
          })
          .catch(() => {
            this.loading = false;
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.login {
  height: auto;

  .login-comp {
    position: relative;
    height: auto;
    padding-top: 40px;
    width: 100%;
    min-width: 992px;
    background-color: rgb(245, 250, 252);

    .login-comp-center {
      margin: 0 auto;

      .spin-nested-loading {
        position: relative;

        .spin-login {
          position: relative;

          .game-list {
            min-height: 716px;
            position: relative;

            .loginForm {
              // position: absolute;
              // top: 50%;
              // left: 50%;
              margin: 0 auto;
              width: 800px;
              min-width: 800px;
              // height: 400px;
              // height: 150px;
              cursor: pointer;
              background-color: rgba(43, 103, 238, 0.1);
              padding: 40px 0;

              .title {
                text-align: center;
                width: 100%;
                height: 66px;
                line-height: 66px;
                font-size: 24px;
                font-size: var(--theme-h1-font-size);
                padding-left: 36px;
                line-height: var(--theme-h1-font-size);
                color: var(--theme-title-color);
                display: inline-block;
                // font-weight: 600;
              }
            }
          }
        }
      }
    }
  }
}

@media (min-width: 1400px) {

  .page-m,
  .comp-m,
  .title-m,
  .about-m,
  .bussiness-m,
  .footer-m,
  .box-m {
    width: 1200px;
  }
}

@media (min-width: 992px) {

  .page-m,
  .comp-m,
  .title-m,
  .about-m,
  .bussiness-m,
  .footer-m,
  .box-m {
    width: 100%;
  }
}
</style>